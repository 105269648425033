/** @jsx jsx */
import OutsideClickHandler from "react-outside-click-handler";
import {jsx, SxStyleProp} from "theme-ui";
import * as React from "react";
import arrows from "./images/arrows.svg";

export interface IDropdownState {
  isOpen: boolean;
  toggle: () => void;
}

export interface IToggleProps {
  showArrows?: boolean;
  customSx?: SxStyleProp;
}

const DropdownCtx = React.createContext({
  isOpen: false,
} as IDropdownState);

export const Toggle: React.FC<IToggleProps> = ({
  children,
  showArrows,
  customSx,
}) => {
  const {isOpen, toggle} = React.useContext(DropdownCtx);
  return (
    <div
      sx={{
        padding: "0.5rem 1rem",
        borderRadius: 0,
        cursor: "pointer",
        transitionDuration: "200ms",
        backgroundColor: isOpen ? "background" : "white",
        display: "flex",
        alignItems: "center",
        "&:hover": {
          backgroundColor: "background",
        },
        ...(customSx != null ? customSx : {}),
      }}
      onClick={toggle}
    >
      {children}

      {showArrows && <img src={arrows} alt="arrows" />}
    </div>
  );
};

export interface DropdownBoddyProps {
  children: React.ReactNode | React.ReactNodeArray;
  customSx?: SxStyleProp;
}

export const Body: React.FC<DropdownBoddyProps> = ({children, customSx}) => {
  const {isOpen, toggle} = React.useContext(DropdownCtx);
  if (!isOpen) {
    return null;
  }

  return (
    <OutsideClickHandler sx={{position: "relative"}} onOutsideClick={toggle}>
      <div
        sx={{
          backgroundColor: "white",
          position: "absolute",
          top: "calc(100% + 0.25rem)",
          padding: "0.5rem 0rem",
          borderRadius: 0,
          border: "gray",
          minWidth: "17.5rem",
          left: 0,
          ...(customSx != null ? customSx : {}),
        }}
      >
        {children}
      </div>
    </OutsideClickHandler>
  );
};

interface IItemProps {
  onClick?: () => any;
  customSx?: SxStyleProp;
}

export const Item: React.FC<IItemProps> = ({children, onClick, customSx}) => {
  const {toggle} = React.useContext(DropdownCtx);

  const handleClick = () => {
    onClick && onClick();
    toggle();
  };

  return (
    <div
      onClick={handleClick}
      sx={{
        padding: "0.5rem 1rem",
        height: "2.5rem",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        transitionDuration: "200ms",
        "&:hover": {
          backgroundColor: "background",
        },
        ...(customSx != null ? customSx : {}),
      }}
    >
      {children}
    </div>
  );
};

const Dropdown: React.FC = ({children}) => {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <DropdownCtx.Provider
      value={{isOpen, toggle: () => setOpen((open) => !open)}}
    >
      <div sx={{position: "relative"}}>{children}</div>
    </DropdownCtx.Provider>
  );
};

export default Dropdown;
