import { Playground, Props } from 'docz';
import { Dropdown, Toggle as DropdownToggle, Item as DropdownItem, Body as DropdownBody, UIKitProvider } from "../../../../../docs/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownBody,
  UIKitProvider,
  React
};