/** @jsx jsx */
import {jsx} from "theme-ui";
import * as React from "react";

export const TestConsoleIcon: React.FC<React.DOMAttributes<SVGElement>> = (
  props
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="#727D8B"
        d="M9.494 9.193c-.881 1.198-1.497 2.339-1.733 2.842-.066.14-.208.227-.363.222l-.388-.012c-.933-.028-1.36-1.182-.668-1.811L7.775 9.13c.39-.354.889-.546 1.399-.554.32-.005.51.358.32.616zm5.929 5.633c.005-.32-.359-.508-.617-.318-1.216.894-2.346 1.498-2.84 1.73-.14.066-.228.209-.223.363l.012.39c.028.934 1.183 1.358 1.811.667l1.303-1.432c.354-.39.546-.89.554-1.4zm-8.281.227l.501-.5c.151-.152.151-.397 0-.548-.151-.151-.396-.151-.547 0l-.502.501c-.244.245-.068.66.274.66.1 0 .198-.037.274-.113zm2.371 2.372l.502-.501c.15-.151.15-.397 0-.548-.152-.151-.397-.15-.548 0l-.501.502c-.245.244-.068.66.274.66.099 0 .198-.038.273-.113zm-2.046-.344l1.702-1.702c.151-.152.151-.397 0-.548-.151-.151-.396-.151-.548 0L6.92 16.533c-.244.245-.068.661.274.661.1 0 .198-.038.274-.113zm8.081-3.764c-.559.479-1.134.908-1.735 1.3-.136.088-.317.069-.431-.046l-3.953-3.953c-.115-.114-.134-.294-.046-.43.43-.66.858-1.219 1.334-1.775 1.612-1.867 4.096-2.742 6.527-2.3l.28.05c.16.03.283.154.312.313l.051.28c.425 2.336-.349 4.844-2.339 6.56zm-.345-4.52c-.65-.65-1.709-.65-2.36 0-.65.65-.65 1.709 0 2.36.65.65 1.71.65 2.36 0 .651-.651.651-1.71 0-2.36zM9.15 11.435c-.163-.164-.44-.124-.547.081-.074.141-.144.278-.214.421-.237.482-.141 1.063.24 1.444l1.99 1.99c.242.242.564.369.89.369.343 0 .545-.117.975-.342.205-.108.245-.385.081-.548L9.15 11.435z"
      />
    </svg>
  );
};
