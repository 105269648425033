/** @jsx jsx */
import "tippy.js/dist/tippy.css";

import { jsx } from "theme-ui";
import React from "react";
import Tippy from "@tippyjs/react";

export const LongTextWithTooltip = (props: { value: string }) => {
  if (!props.value.length) {
    return null;
  }
  const [visible, setVisible] = React.useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const isToolTipRequired = props.value.length > 29;

  return (
    <React.Fragment>
      <Tippy content={props.value} visible={isToolTipRequired && visible}>
        <div
          onMouseEnter={() => show()}
          onMouseLeave={() => hide()}
          sx={{
            width: "calc(100% - 2rem)",
            overflow: "hidden",
            lineHeight: 1.5,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {props.value}
        </div>
      </Tippy>
    </React.Fragment>
  );
};
