/** @jsx jsx */
import * as React from "react";
import {jsx, SxStyleProp} from "theme-ui";

interface IProps {
  label: string;
  htmlFor: string;
  className?: string;
  required?: boolean;
  customSx?: {
    root?: SxStyleProp;
    label?: SxStyleProp;
  };
  customStyles?: React.CSSProperties;
}

export const Field: React.FC<IProps> = ({
  className,
  label,
  children,
  htmlFor,
  required,
  customSx,
  customStyles,
}) => {
  return (
    <div
      sx={{
        marginBottom: "1rem",
        ...(customSx != null && customSx.root != null ? customSx.root : {}),
      }}
      className={className}
      style={customStyles}
    >
      <label
        htmlFor={htmlFor}
        sx={{
          display: "block",
          fontSize: 0,
          fontWeight: "500",
          marginBottom: "0.5rem",
          maxWidth: "fit-content",
          ...(customSx != null && customSx.label != null ? customSx.label : {}),
        }}
        css={{
          "&.required:after": {
            color: "#df5657",
            content: `" *"`,
          },
        }}
        className={required ? "required" : ""}
      >
        {label}
      </label>
      {children}
    </div>
  );
};

export default {
  Field,
};
