// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---docs-buttons-mdx": () => import("./../../../../docs/buttons.mdx" /* webpackChunkName: "component---docs-buttons-mdx" */),
  "component---docs-card-mdx": () => import("./../../../../docs/Card.mdx" /* webpackChunkName: "component---docs-card-mdx" */),
  "component---docs-description-mdx": () => import("./../../../../docs/Description.mdx" /* webpackChunkName: "component---docs-description-mdx" */),
  "component---docs-dropdown-mdx": () => import("./../../../../docs/Dropdown.mdx" /* webpackChunkName: "component---docs-dropdown-mdx" */),
  "component---docs-forms-mdx": () => import("./../../../../docs/forms.mdx" /* webpackChunkName: "component---docs-forms-mdx" */),
  "component---docs-header-mdx": () => import("./../../../../docs/Header.mdx" /* webpackChunkName: "component---docs-header-mdx" */),
  "component---docs-icon-mdx": () => import("./../../../../docs/Icon.mdx" /* webpackChunkName: "component---docs-icon-mdx" */),
  "component---docs-input-mdx": () => import("./../../../../docs/Input.mdx" /* webpackChunkName: "component---docs-input-mdx" */),
  "component---docs-intro-mdx": () => import("./../../../../docs/Intro.mdx" /* webpackChunkName: "component---docs-intro-mdx" */),
  "component---docs-link-mdx": () => import("./../../../../docs/Link.mdx" /* webpackChunkName: "component---docs-link-mdx" */),
  "component---docs-long-text-mdx": () => import("./../../../../docs/LongText.mdx" /* webpackChunkName: "component---docs-long-text-mdx" */),
  "component---docs-modal-mdx": () => import("./../../../../docs/Modal.mdx" /* webpackChunkName: "component---docs-modal-mdx" */),
  "component---docs-provider-mdx": () => import("./../../../../docs/Provider.mdx" /* webpackChunkName: "component---docs-provider-mdx" */),
  "component---docs-radio-group-mdx": () => import("./../../../../docs/RadioGroup.mdx" /* webpackChunkName: "component---docs-radio-group-mdx" */),
  "component---docs-secret-key-viewer-mdx": () => import("./../../../../docs/SecretKeyViewer.mdx" /* webpackChunkName: "component---docs-secret-key-viewer-mdx" */),
  "component---docs-select-mdx": () => import("./../../../../docs/Select.mdx" /* webpackChunkName: "component---docs-select-mdx" */),
  "component---docs-side-menu-mdx": () => import("./../../../../docs/SideMenu.mdx" /* webpackChunkName: "component---docs-side-menu-mdx" */),
  "component---docs-user-avatar-mdx": () => import("./../../../../docs/UserAvatar.mdx" /* webpackChunkName: "component---docs-user-avatar-mdx" */),
  "component---docs-workspace-mdx": () => import("./../../../../docs/Workspace.mdx" /* webpackChunkName: "component---docs-workspace-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

