/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";

export const JobsIcon: React.FC<React.DOMAttributes<SVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9C5 7.34315 6.34315 6 8 6H16C17.6569 6 19 7.34315 19 9V15C19 16.6569 17.6569 18 16 18H8C6.34315 18 5 16.6569 5 15V9ZM15.4204 11.7757L13.7137 9.63344C13.6843 9.59676 13.6479 9.56627 13.6066 9.54371C13.5653 9.52115 13.5199 9.50698 13.4732 9.502C13.4264 9.49703 13.3791 9.50134 13.334 9.51471C13.2889 9.52807 13.2468 9.55022 13.2103 9.57988C13.1736 9.60934 13.1431 9.64577 13.1206 9.68706C13.098 9.72834 13.0839 9.77368 13.0789 9.82046C13.0739 9.86725 13.0782 9.91455 13.0916 9.95966C13.1049 10.0048 13.1271 10.0468 13.1568 10.0833L14.6813 12.0006L13.0818 13.918C13.0517 13.9541 13.0291 13.9957 13.0151 14.0406C13.0012 14.0854 12.9962 14.1326 13.0005 14.1793C13.0048 14.2261 13.0183 14.2715 13.0402 14.3131C13.0621 14.3546 13.092 14.3914 13.1282 14.4214C13.1929 14.4734 13.2737 14.5011 13.3567 14.5C13.4092 14.5 13.461 14.4886 13.5085 14.4664C13.556 14.4441 13.5981 14.4117 13.6316 14.3714L15.4168 12.2292C15.4699 12.1657 15.4992 12.0858 15.4999 12.0031C15.5005 11.9204 15.4724 11.84 15.4204 11.7757ZM10.2855 9.63344L8.57943 11.7757C8.52743 11.84 8.49936 11.9204 8.50001 12.0031C8.50066 12.0858 8.52999 12.1657 8.58299 12.2292L10.3676 14.3714C10.4012 14.4117 10.4432 14.4441 10.4907 14.4664C10.5382 14.4886 10.59 14.5 10.6424 14.5C10.7254 14.5011 10.8062 14.4734 10.8709 14.4214C10.907 14.3914 10.9369 14.3546 10.9588 14.3131C10.9807 14.2715 10.9942 14.2261 10.9985 14.1793C11.0028 14.1326 10.9978 14.0854 10.9839 14.0406C10.97 13.9957 10.9473 13.9541 10.9173 13.918L9.31826 12.0006L10.8423 10.0833C10.872 10.0468 10.8941 10.0048 10.9075 9.95966C10.9208 9.91455 10.9252 9.86725 10.9202 9.82046C10.9152 9.77368 10.901 9.72834 10.8785 9.68706C10.8559 9.64577 10.8255 9.60934 10.7888 9.57988C10.7523 9.55022 10.7103 9.52807 10.6652 9.51471C10.6201 9.50134 10.5728 9.49703 10.526 9.502C10.4793 9.50698 10.4339 9.52115 10.3927 9.54371C10.3514 9.56627 10.315 9.59676 10.2855 9.63344Z"
      />
    </svg>
  );
};
