/** @jsx jsx */
import * as React from "react";
import { jsx, useThemeUI, Theme, SxStyleProp } from "theme-ui";
import Icon from "./Icon";
import { Link } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";

interface IMenuItemProps {
  isActive?: boolean;
  label: string;
  icon?: string;
  to: string;
  svg?: SVGElement;
  iconSize?: string;
  customSx?: {
    link?: SxStyleProp;
    icon?: SxStyleProp;
    label?: SxStyleProp;
  };
}

export type SideMenuItems = Array<
  (location: { pathname: string }) => IMenuItemProps
>;

export const MenuItem: React.FC<
  IMenuItemProps & {
    theme: Theme;
  }
> = ({ icon, label, isActive, to, theme, svg, iconSize, customSx }) => {
  const [isHovering, setHovering] = React.useState(false);
  return (
    <Link
      to={to}
      style={{
        textDecoration: "none",
      }}
      sx={customSx != null && customSx.link ? customSx.link : {}}
    >
      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        sx={{
          height: "2rem",
          display: "flex",
          alignItems: "center",
          padding: "0rem 1rem",
          borderRadius: 0,
          cursor: "pointer",
          color: "textSecondary",
          transitionDuration: "200ms",
          marginBottom: "0.5rem",
          ...(isActive
            ? {
                backgroundColor: "hoverGray",
                color: "primary",
              }
            : {}),
          "&:hover": {
            backgroundColor: "hoverGray",
            color: "primary",
          },
        }}
      >
        <span
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {(icon || svg) && (
            <Icon
              icon={icon}
              svg={svg}
              color={
                isHovering || isActive
                  ? (theme.colors!.primary as string)
                  : (theme.colors!.textSecondary as string)
              }
              size={iconSize}
              customSx={customSx != null && customSx.icon ? customSx.icon : {}}
            />
          )}{" "}
          <span
            sx={{
              marginLeft: "0.5rem",
              ...(customSx != null && customSx.label ? customSx.label : {}),
            }}
          >
            {label}
          </span>
        </span>
      </div>
    </Link>
  );
};

const SideMenu: React.FC<
  RouteComponentProps & {
    items: SideMenuItems;
  }
> = ({ location, items }) => {
  const { theme } = useThemeUI();
  return (
    <div
      sx={{
        width: "10rem",
      }}
    >
      {items.map(
        (
          item: (location: { pathname: string }) => IMenuItemProps,
          index: number
        ) => (
          <MenuItem
            {...{ ...item(location), theme, key: `menu-item__${index}` }}
          />
        )
      )}
    </div>
  );
};

export default withRouter(SideMenu);
