/** @jsx jsx */
import {jsx, SxStyleProp} from "theme-ui";
import * as React from "react";

interface IProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
  customSx?: {
    root?: SxStyleProp;
    wrapper?: SxStyleProp;
  };
}

const Header: React.FC<IProps> = ({left, right, customSx = {}}) => {
  return (
    <div
      sx={{
        height: "3rem",
        backgroundColor: "white",
        borderBottom: "gray",
        marginBottom: "2rem",
        ...(customSx.root ? customSx.root : {}),
      }}
    >
      <div
        sx={{
          maxWidth: "65rem",
          width: "100%",
          height: "100%",
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ...(customSx.wrapper ? customSx.wrapper : {}),
        }}
      >
        <div>{left}</div>

        <div>{right}</div>
      </div>
    </div>
  );
};

export default Header;
