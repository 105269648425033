/** @jsx jsx */
import * as React from "react";
import { jsx, SxStyleProp } from "theme-ui";

export interface IProps {
  id: string;
  type?: "text" | "password" | "email" | "number";
  onChange?: (newVal: string, event?: React.FormEvent<HTMLInputElement>) => any;
  onBlur?: (e: React.FormEvent) => void;
  onFocus?: (e: React.FormEvent) => void;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  value?: string;
  required?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  customStyles?: SxStyleProp;
  min?: number;
  max?: number;
  disabled?: boolean;
}

const Input: React.FC<IProps> = ({
  id,
  required,
  type,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  value,
  placeholder,
  autoFocus,
  customStyles,
  min,
  max,
  disabled,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (autoFocus) {
      inputRef.current!.focus();
    }
  }, [autoFocus]);
  return (
    <input
      disabled={disabled}
      id={id}
      ref={inputRef}
      sx={{
        height: "2.5rem",
        border: "gray",
        backgroundColor: "border",
        width: "100%",
        borderRadius: 0,
        padding: "0rem 0.5rem",
        transitionDuration: "200ms",
        fontSize: 0,
        "&:focus": {
          outline: "none",
          border: "blue",
        },
        ...(customStyles != null ? customStyles : {}),
      }}
      onChange={(e) => onChange && onChange(e.target.value, e)}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      type={type}
      value={value}
      required={required}
      placeholder={placeholder}
      css={{
        "input[type=number]::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "input[type=number]::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      }}
      {...(type === "number" ? { min, max } : {})}
    />
  );
};

export default Input;
