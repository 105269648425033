/** @jsx jsx */
import {jsx} from "theme-ui";
import * as React from "react";

export const KeyIcon: React.FC<React.DOMAttributes<SVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g>
        <path d=" M 16.87 7.131 C 15.362 5.624 12.909 5.624 11.4 7.131 C 10.371 8.161 10.013 9.679 10.46 11.056 L 6.103 15.412 C 6.037 15.477 6 15.567 6 15.66 L 6 17.649 C 6 17.843 6.157 18 6.352 18 L 8.34 18 C 8.434 18 8.523 17.963 8.589 17.897 L 9.086 17.4 C 9.162 17.324 9.199 17.218 9.186 17.111 L 9.125 16.577 L 9.865 16.507 C 10.033 16.491 10.166 16.358 10.182 16.19 L 10.252 15.45 L 10.786 15.512 C 10.886 15.525 10.985 15.492 11.06 15.426 C 11.135 15.359 11.178 15.263 11.178 15.163 L 11.178 14.508 L 11.821 14.508 C 11.914 14.508 12.003 14.471 12.069 14.405 L 12.971 13.515 C 14.347 13.962 15.839 13.631 16.869 12.601 C 18.377 11.093 18.377 8.639 16.869 7.131 L 16.87 7.131 Z  M 15.875 9.617 C 15.465 10.029 14.795 10.029 14.385 9.617 C 13.973 9.207 13.973 8.537 14.385 8.126 C 14.795 7.715 15.465 7.715 15.875 8.126 C 16.287 8.537 16.287 9.206 15.875 9.617 Z " />
      </g>
    </svg>
  );
};
