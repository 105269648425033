/** @jsx jsx */
import * as React from "react";
import {jsx} from "theme-ui";

interface IProps {
  className?: string;
}

const Card: React.FC<IProps> = ({children, className}) => {
  return (
    <div
      className={className}
      sx={{
        backgroundColor: "white",
        padding: "1.5rem",
        borderRadius: 1,
        boxShadow: "main",
      }}
    >
      {children}
    </div>
  );
};

export default Card;
