import { Playground, Props } from 'docz';
import { UIKitProvider, LongTextWithTooltip, LongTextWithCopyAction } from "../../../../../docs/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  UIKitProvider,
  LongTextWithTooltip,
  LongTextWithCopyAction,
  React
};