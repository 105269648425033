import { Playground, Props } from 'docz';
import { UIKitProvider, SideMenu, MenuItem } from "../../../../../docs/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  UIKitProvider,
  SideMenu,
  MenuItem,
  React
};