import { Playground, Props } from 'docz';
import { UIKitProvider, Select, SelectChangeValue } from "../../../../../docs/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  UIKitProvider,
  Select,
  SelectChangeValue,
  React
};