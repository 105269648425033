/** @jsx jsx */
import { jsx, SxStyleProp } from "theme-ui";
import * as React from "react";
import { v1 } from "uuid";
import { IRow } from './DescriptionList'

export interface IProps {
  row: IRow;
  isLast?: boolean;
}

const DescriptionList: React.FC<IProps> = ({
  row,
  isLast,
}) => {
  return (
    <div
      key={v1()}
      sx={{
        padding: "1.25rem 0rem",
        display: "flex",
        borderBottom: isLast ? "none" : "gray",
        ...(row.customSx != null ? row.customSx : {}),
      }}
    >
      {row.columns.map((column) => (
        <div
          key={v1()}
          sx={{
            flex: column.flex || 1,
            ...(column.customSx != null &&
              column.customSx.container != null
              ? column.customSx.container
              : {}),
          }}
        >
          {column.label && (
            <div
              sx={{
                fontSize: 3,
                color: "textSecondary",
                fontWeight: 500,
                marginBottom: "0.5rem",
                ...(column.customSx != null &&
                  column.customSx.label != null
                  ? column.customSx.label
                  : {}),
              }}
            >
              {column.label}
            </div>
          )}
          {column.value && (
            <div
              onClick={column.onClick}
              sx={{
                ...(column.customSx != null &&
                  column.customSx.value != null
                  ? column.customSx.value
                  : {}),
              }}
            >
              {column.value}
            </div>
          )}
        </div>
      ))}
    </div>)
};

export default DescriptionList;
