/** @jsx jsx */
import {jsx} from "theme-ui";
import * as React from "react";
import RModal, {Styles} from "react-modal";
import closeIcon from "./images/close-icon.svg";

const styles: Styles = {
  content: {
    top: "50vh",
    left: "50vw",
    right: "initial",
    bottom: "initial",
    border: "none",
    borderRadius: 8,
    boxShadow: "0px 32px 98px rgba(18, 18, 18, 0.11)",
    padding: "1.5rem",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(114, 125, 139, 0.16)",
  },
};

interface IProps {
  width?: string;
  isOpen: boolean;
  onRequestClose?: () => void;
  appElement: HTMLElement;
  customStyles?: ReactModal.Styles;
}

const Modal: React.FC<IProps> = ({
  isOpen,
  width,
  onRequestClose,
  children,
  appElement,
  customStyles,
}) => {
  return (
    <RModal
      isOpen={isOpen}
      style={{
        content: {
          ...styles.content,
          width,
          maxWidth: "75vw",
          maxHeight: "75vh",
          ...(customStyles != null && customStyles.content != null
            ? customStyles.content
            : {}),
        },
        overlay: {
          ...styles.overlay,
          ...(customStyles != null && customStyles.overlay != null
            ? customStyles.overlay
            : {}),
        },
      }}
      onRequestClose={onRequestClose}
      appElement={appElement}
    >
      <img
        alt="X"
        sx={{
          position: "absolute",
          top: "1rem",
          cursor: "pointer",
          right: "1rem",
        }}
        onClick={onRequestClose}
        src={closeIcon}
      />
      {children}
    </RModal>
  );
};

Modal.defaultProps = {
  width: "22.5rem",
};

export default Modal;
