/** @jsx jsx */
import {jsx} from "theme-ui";
import * as React from "react";

export const PeopleIcon: React.FC<React.DOMAttributes<SVGElement>> = (
  props
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M9.667 11.333c.527 0 1.043-.156 1.481-.45.439-.292.78-.709.982-1.196.202-.487.255-1.023.152-1.54-.103-.518-.357-.993-.73-1.366-.373-.373-.848-.627-1.365-.73-.517-.103-1.054-.05-1.54.152-.488.202-.905.544-1.198.982C7.156 7.624 7 8.14 7 8.667c0 .707.28 1.385.781 1.885.5.5 1.178.781 1.886.781zM15 12.667c.396 0 .782-.118 1.111-.337.329-.22.585-.533.737-.898.151-.365.19-.768.114-1.155-.078-.388-.268-.745-.548-1.025-.28-.28-.636-.47-1.024-.547-.388-.077-.79-.037-1.155.114-.366.151-.678.408-.898.737-.22.328-.337.715-.337 1.11 0 .531.21 1.04.586 1.415.375.375.884.586 1.414.586zM17.667 17.333c.176 0 .346-.07.471-.195s.195-.295.195-.471c0-.623-.175-1.234-.505-1.762-.33-.529-.802-.954-1.361-1.229-.56-.274-1.185-.386-1.805-.323-.62.063-1.21.299-1.702.68-.653-.65-1.484-1.093-2.388-1.271-.904-.18-1.841-.086-2.693.267-.85.353-1.579.95-2.091 1.716-.513.766-.787 1.667-.788 2.588 0 .177.07.347.195.472s.295.195.472.195h8c.177 0 .346-.07.471-.195s.195-.295.195-.472" />
    </svg>
  );
};
