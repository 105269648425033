/** @jsx jsx */
import {jsx} from "theme-ui";
import * as React from "react";

export const WorkspaceIcon: React.FC<{initial: string}> = ({initial}) => {
  return (
    <div
      sx={{
        width: "1.5rem",
        height: "1.5rem",
        backgroundColor: "gray",
        fontSize: "12px",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 500,
        borderRadius: 0,
        textTransform: "uppercase",
      }}
    >
      {initial}
    </div>
  );
};

const Workspace: React.FC<{name: string}> = ({name}) => {
  const initial = name[0];
  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <WorkspaceIcon initial={initial} />

      <div
        sx={{
          marginLeft: "0.5rem",
        }}
      >
        {name}
      </div>
    </div>
  );
};

export default Workspace;
