import { CopyIcon } from "./Copy";
import { AddIcon } from "./Add";
import { KeyIcon } from "./Key";
import { PeopleIcon } from "./People";
import { DangerIcon } from "./Danger";
import { TestConsoleIcon } from "./TestConsole";
import { PlatformsIcon } from "./Platforms";
import { WorkspaceIcon } from "./Workspace";
import { DropdownArrowsIcon } from "./DropdownArrowsIcon";
import { EditIcon } from "./Edit";
import { ImageIcon } from "./Image";
import { CloseIcon } from "./Close";
import { EmployersIcon } from "./Employers";
import { AdminsIcon } from "./Admins";
import { WarningIcon } from "./Warning";
import { FunnelIcon } from "./Funnel";
import { JobsIcon } from "./Jobs";

export {
  CopyIcon,
  AddIcon,
  KeyIcon,
  PeopleIcon,
  DangerIcon,
  TestConsoleIcon,
  PlatformsIcon,
  DropdownArrowsIcon,
  WorkspaceIcon,
  EditIcon,
  ImageIcon,
  CloseIcon,
  EmployersIcon,
  AdminsIcon,
  WarningIcon,
  FunnelIcon,
  JobsIcon,
};
