/** @jsx jsx */
import {jsx} from "theme-ui";
import * as React from "react";
import Select, {ValueType, Props, IndicatorProps} from "react-select";
import {DropdownArrowsIcon} from "./icons/DropdownArrowsIcon";

export type SelectChangeValue =
  | ValueType<{value: any; label: string}>
  | null
  | undefined;

type CustomSelectProps = Props & {
  onChangeHandler?: (value: any) => void;
};

const customStyles = {
  control: (provided: React.CSSProperties) => ({
    ...provided,
    backgroundColor: "#e7ebed",
    border: "none",
  }),
  indicatorSeparator: (provided: React.CSSProperties) => ({
    ...provided,
    display: "none",
  }),
};

export const DropdownIndicator = (props: IndicatorProps<any>) => {
  const {cx, getStyles, innerProps} = props;
  const nextClassName = cx(
    {
      indicator: true,
      "dropdown-indicator": true,
    },
    "customDropdown",
  );
  return (
    <div
      {...innerProps}
      style={getStyles("dropdownIndicator", props)}
      className={nextClassName}
    >
      <div sx={{width: "1.5rem", height: "1.5rem", margin: "auto"}}>
        <DropdownArrowsIcon sx={{fill: "#21292e"}} />
      </div>
    </div>
  );
};

export default (props: CustomSelectProps) => {
  const {onChangeHandler, ...rest} = props;
  return (
    <Select
      onChange={(value: SelectChangeValue) =>
        props.onChangeHandler &&
        props.onChangeHandler(value != null ? (value as {value: string; label: string}).value : "")
      }
      styles={customStyles}
      components={{
        DropdownIndicator,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary75: "rgba(79, 154, 202, 0.75)",
          primary50: "rgba(79, 154, 202, 0.5)",
          primary25: "rgba(79, 154, 202, 0.25)",
          primary: "#4F9ACA",
        },
      })}
      {...rest}
    />
  );
};
