/** @jsx jsx */
import * as React from "react";
import { useThemeUI, jsx, SxStyleProp } from "theme-ui";
import {
  AddIcon,
  KeyIcon,
  PeopleIcon,
  WorkspaceIcon,
  TestConsoleIcon,
  PlatformsIcon,
  EmployersIcon,
  AdminsIcon,
} from "./icons";

const localSvgMapping: {
  [key: string]: React.FC<React.DOMAttributes<SVGElement>>;
} = {
  add: AddIcon,
  key: KeyIcon,
  people: PeopleIcon,
  workspace: WorkspaceIcon,
  testConsole: TestConsoleIcon,
  platforms: PlatformsIcon,
  employers: EmployersIcon,
  admins: AdminsIcon,
};

interface Props {
  icon?: string;
  color?: string;
  size?: string;
  transitionDuration?: string;
  svg?: SVGElement;
  customSx?: SxStyleProp;
}

const Icon: React.FC<Props> = ({
  transitionDuration,
  icon,
  color,
  size,
  svg,
  customSx,
}) => {
  const { theme } = useThemeUI();
  let NextSvg: any = svg;
  if (icon != null && icon in localSvgMapping && !svg) {
    NextSvg = localSvgMapping[icon];
  }
  if (!NextSvg) {
    return null;
  }
  return (
    <div
      sx={{
        width: size,
        height: size,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg path": {
          fill: color || (theme.colors!["textSecondary"] as string),
          transitionDuration: transitionDuration || "0.2s",
        },
        ...(customSx || {}),
      }}
    >
      <NextSvg
        sx={{
          fill: color || (theme.colors!["textSecondary"] as string),
          width: size,
          height: size,
        }}
      />
    </div>
  );
};

Icon.defaultProps = {
  size: "1rem",
};

export default Icon;
