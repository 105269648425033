/** @jsx jsx */
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

import { jsx, SxStyleProp } from "theme-ui";
import React, { ReactElement } from "react";
import Tippy from "@tippyjs/react";
import { CopyIcon } from "./icons/Copy";

type Props = {
  value: string; characterLimit?: number;
  iconSize?: string,
  customSx?: {
    container?: SxStyleProp,
    value?: SxStyleProp,
    icon?: SxStyleProp
  },
  icon?: ReactElement
};

export default (props: Props) => {
  const { customSx, value, characterLimit, icon, iconSize } = props;
  if (!value.length) {
    return null;
  }
  const [visible, setVisible] = React.useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const copyToClipboard = (value: string) => {
    const tempElement = document.createElement("textarea");
    tempElement.value = value;
    tempElement.setAttribute("readonly", "");
    tempElement.style.position = "absolute";
    tempElement.style.left = "-9999px";
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand("copy");
    document.body.removeChild(tempElement);
    show();
    setTimeout(() => hide(), 500);
  };

  return (
    <React.Fragment>
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          flexFlow: "row",
          ...customSx?.container
        }}
      >
        <div
          sx={{
            overflow: "hidden",
            lineHeight: 1.5,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            ...customSx?.value
          }}
        >
          {characterLimit && characterLimit < value.length ? `${value.substr(0, characterLimit - 1)}...` : value}
        </div>
        <Tippy
          theme="light"
          content="Copied!"
          visible={visible}
          onClickOutside={hide}
        >
          <div
            sx={{
              marginLeft: "6px",
              width: iconSize || "20px",
              height: "auto",
              cursor: "pointer",
              display: "flex",
              flexFlow: "column",
              alignItems: "center",
              ...customSx?.icon,
            }}
          >
            {icon || <CopyIcon onClick={() => copyToClipboard(value)} />}
          </div>
        </Tippy>
      </div>
    </React.Fragment >
  );
};
