import { Playground, Props } from 'docz';
import { Button, PrimaryButton, UIKitProvider, BUTTON_SIZE } from "../../../../../docs/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  Button,
  PrimaryButton,
  UIKitProvider,
  BUTTON_SIZE,
  React
};