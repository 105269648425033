module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/Users/yogurtandjam/Pinwheel/uikit/node_modules/gatsby-theme-docz/src/base/Layout.js"},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/yogurtandjam/Pinwheel/uikit/.docz"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{},"src":"./","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":["Intro","Components"],"mdPlugins":[],"hastPlugins":[],"ignore":["README.md"],"typescript":true,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"/Users/yogurtandjam/Pinwheel/uikit/.docz","base":"/","source":"./","gatsby-root":null,"files":"**/*.{md,markdown,mdx}","public":"/public","dest":"/build","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"Uikit","description":"UI package for pinwheel projects","host":"localhost","port":"5005","p":3000,"separator":"-","paths":{"root":"/Users/yogurtandjam/Pinwheel/uikit","templates":"/Users/yogurtandjam/Pinwheel/uikit/node_modules/docz-core/dist/templates","docz":"/Users/yogurtandjam/Pinwheel/uikit/.docz","cache":"/Users/yogurtandjam/Pinwheel/uikit/.docz/.cache","app":"/Users/yogurtandjam/Pinwheel/uikit/.docz/app","appPackageJson":"/Users/yogurtandjam/Pinwheel/uikit/package.json","appTsConfig":"/Users/yogurtandjam/Pinwheel/uikit/tsconfig.json","gatsbyConfig":"/Users/yogurtandjam/Pinwheel/uikit/gatsby-config.js","gatsbyBrowser":"/Users/yogurtandjam/Pinwheel/uikit/gatsby-browser.js","gatsbyNode":"/Users/yogurtandjam/Pinwheel/uikit/gatsby-node.js","gatsbySSR":"/Users/yogurtandjam/Pinwheel/uikit/gatsby-ssr.js","importsJs":"/Users/yogurtandjam/Pinwheel/uikit/.docz/app/imports.js","rootJs":"/Users/yogurtandjam/Pinwheel/uikit/.docz/app/root.jsx","indexJs":"/Users/yogurtandjam/Pinwheel/uikit/.docz/app/index.jsx","indexHtml":"/Users/yogurtandjam/Pinwheel/uikit/.docz/app/index.html","db":"/Users/yogurtandjam/Pinwheel/uikit/.docz/app/db.json"}},
    }]
