/** @jsx jsx */
import {jsx} from "theme-ui";
import * as React from "react";

export const WorkspaceIcon: React.FC<React.DOMAttributes<SVGElement>> = (
  props
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M17.86 11.56l-2.227-4.453c-.166-.333-.422-.614-.739-.809-.317-.196-.682-.299-1.054-.298h-3.68c-.372 0-.737.102-1.054.298-.317.196-.573.476-.74.809L6.14 11.56c-.093.186-.14.392-.14.6V16c0 .53.21 1.04.586 1.414C6.96 17.79 7.47 18 8 18h8c.53 0 1.04-.21 1.414-.586C17.79 17.04 18 16.53 18 16v-3.84c0-.208-.047-.414-.14-.6zm-8.527 3.773c-.132 0-.26-.039-.37-.112-.11-.073-.195-.177-.246-.3-.05-.121-.063-.255-.038-.384.026-.13.09-.248.183-.342.093-.093.212-.156.341-.182.13-.026.264-.013.385.038.122.05.226.136.3.245.073.11.112.239.112.37 0 .178-.07.347-.195.472s-.295.195-.472.195zm5.334 0H12c-.177 0-.346-.07-.471-.195s-.196-.294-.196-.471.07-.347.196-.472c.125-.125.294-.195.471-.195h2.667c.177 0 .346.07.471.195s.195.295.195.472-.07.346-.195.471-.294.195-.471.195zm-6.92-4L9.56 7.7c.056-.11.142-.204.248-.269.106-.065.228-.099.352-.098h3.68c.124 0 .246.033.352.098.106.065.192.158.248.269l1.813 3.633H7.747z" />
    </svg>
  );
};
