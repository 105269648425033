/** @jsx jsx */
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import {jsx} from "theme-ui";
import * as React from "react";
import {Button} from "./buttons";
import Tippy from "@tippyjs/react";
import {CopyIcon} from "./icons/Copy";

const BlurredText: React.FC = ({children}) => (
  <div
    sx={{
      color: "transparent",
      textShadow: "0 0 6px rgba(0, 0, 0, 0.2)",
    }}
  >
    {children}
  </div>
);

const SecretKeyViewer: React.FC<{
  secretKey: string;
}> = ({secretKey}) => {
  const [revealed, setRevealed] = React.useState(false);
  const [tooltipVisible, setTooltipVisibility] = React.useState(false);

  if (revealed) {
    const show = () => setTooltipVisibility(true);
    const hide = () => setTooltipVisibility(false);
    const copyToClipboard = (value: string) => {
      const tempElement = document.createElement("textarea");
      tempElement.value = value;
      tempElement.setAttribute("readonly", "");
      tempElement.style.position = "absolute";
      tempElement.style.left = "-9999px";
      document.body.appendChild(tempElement);
      tempElement.select();
      document.execCommand("copy");
      document.body.removeChild(tempElement);
      show();
      setTimeout(() => hide(), 500);
    };

    return (
      <div
        sx={{
          display: "flex",
          flexFlow: "row",
          alignItems: "center",
        }}
      >
        <div
          sx={{
            width: "56ch",
            lineHeight: "1.5rem",
            fontSize: "1rem",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {secretKey}
        </div>
        <Tippy
          theme="light"
          content="Copied!"
          visible={tooltipVisible}
          onClickOutside={hide}
        >
          <div
            sx={{
              marginLeft: "1rem",
              width: "1.2rem",
              height: "auto",
              cursor: "pointer",
              display: "flex",
              flexFlow: "column",
              alignItems: "center",
            }}
          >
            <CopyIcon
              sx={{margin: "auto"}}
              onClick={() => copyToClipboard(secretKey)}
            />
          </div>
        </Tippy>
      </div>
    );
  }

  return (
    <div
      sx={{
        position: "relative",
        width: "56ch",
      }}
    >
      <BlurredText>{secretKey}</BlurredText>

      <div
        sx={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translate(-50%, -0.25rem)",
        }}
      >
        <Button size="small" onClick={() => setRevealed(true)}>
          Reveal Secret
        </Button>
      </div>
    </div>
  );
};

export default SecretKeyViewer;
